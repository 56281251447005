import { throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { captureError } from '@seequent/web-logger';

import { AsyncStateError, BaseAction } from '../../types';

import { parseErrorMessage } from './parseError';

export function createError({ payload }: Omit<BaseAction<AjaxError>, 'type'>): AsyncStateError {
    const { status, response, message: ajaxErrMsg } = payload;
    // if response is null (i.e CORS error) return the message from ajax, not the network response error
    return { status, message: parseErrorMessage(response) || ajaxErrMsg };
}

export function captureAndThrowError(error: Error) {
    captureError(error);
    return throwError(() => error);
}
